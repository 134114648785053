import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { addDays, addMinutes, setHours, setMinutes, addYears } from 'date-fns';
import Notify from '../components/common/Notify';
import * as Login from './Login';

/**
 * This ts file handles all API calls to/from SubmissionController.cs
 * */

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SubmissionState {
    data: ParentChange[],
    archiveData: ParentChange[],
    pendingData: ParentChange[],
    filterLists: [],
    archiveFilterLists: [],
    pendingFilterLists: [],
    isLoading: boolean,
    isLoaded: boolean,
    archiveDataLoading: boolean,
    archiveDataLoaded: boolean,
    pendingDataLoading: boolean,
    pendingDataLoaded: boolean,
    refresh: boolean,
    refreshDate: Date,
    pendingRefreshDate: Date,
    archiveRefreshDate: Date
}

export interface ParentChange {
    id: number;
    status: string;
    description: string;
    interconnect: string;
    createdOn: number;
    createdBy: string;
    updatedOn: number;
    updatedBy: string;
    adminComments: string;
    detailChanges: Change[];
}

export interface Change {
    id: number;
    submissionType: string;
    status: string;
    dataItem: string;
    dataItemOld: string;
    type: string;
    deviceId: string;
    deviceType: string;
    qualityStatus: string;
    tsStatus: string;
    interconnect: string;
    remoteName: string;
    areaEms: string;
    emsSubstation: string;
    unitMeas: string;
    remoteAddOrRemove: [];
    comments: string;
    createdBy: string;
    createdByRemote: string;
    createdOn: number;
    updatedBy: string;
    updatedOn: string;
    deletedBy: string;
    deletedOn: number;
    adminComments: string;
    isComplete: boolean;
    isDeleted: boolean;
    detailId: number;
    parentId: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestDataAction {
    type: 'CHANGES_REQUEST_DATA';
    dataType: string;
}

interface ReceiveDataAction {
    type: 'CHANGES_RECEIVE_DATA';
    changes: ParentChange[];
    filterLists: [];
    dataType: string;
    refreshDate: Date;
}

interface CreateChangeAction {
    type: 'CHANGES_CREATE_CHANGE';
}

interface UpdateDataAction {
    type: 'CHANGES_UPDATE_DATA';
}

interface RefreshDataAction {
    type: 'CHANGES_REFRESH_DATA';
}

interface CancelChangeAction {
    type: 'CHANGES_CANCEL_CHANGE'
}

interface ApproveChangeAction {
    type: 'CHANGES_APPROVE_CHANGE'
}

interface RejectChangeAction {
    type: 'CHANGES_REJECT_CHANGE'
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestDataAction | ReceiveDataAction | CreateChangeAction | UpdateDataAction | RefreshDataAction | CancelChangeAction | ApproveChangeAction | RejectChangeAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

// All of the below fetch API calls go to the SubmissionController.cs file
export const actionCreators = {
    requestData: (interconnect: string, dataType: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let user = Login.actionCreators.getUser();
        const appState = getState();

        if (appState && appState.submissions &&
            ((dataType === 'current' && !appState.submissions.isLoaded && !appState.submissions.isLoading) ||
            (dataType === 'archive' && !appState.submissions.archiveDataLoaded && !appState.submissions.archiveDataLoading) ||
            (dataType === 'pending' && !appState.submissions.pendingDataLoaded && !appState.submissions.pendingDataLoading)
            )) {
            fetch(`api/change/` + dataType, {
                method: 'POST',
                body: JSON.stringify({
                    UserName: user.userName,
                    Interconnect: interconnect,
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        (response.json())// as Promise<ParentChange[]>)
                            .then(data => {
                                // declare a new date of the current date/time
                                let refreshDate = new Date();
                                // set refreshDate to be next day at 1:20 AM if data type is Archive
                                // since Archive changes are only updated per the nightly refresh that evaluates submissions
                                // for auto-complete requirements
                                if (dataType === 'archive') {
                                    // Add a day to the refreshDate
                                    refreshDate = addDays(refreshDate, 1);
                                    // set the hour of refreshDate to 1 AM
                                    refreshDate = setHours(refreshDate, 1);
                                    // set the minutes of refreshDate to 20, so final time is next day @ 1:20 AM
                                    refreshDate = setMinutes(refreshDate, 20);
                                }
                                // TO DO FIGURE OUT ISSUE W/ CERT ERROR WHEN REFRESH TIMER IS 5 MINUTES
                                else
                                {
                                    // Check if the user has set the refresh timer via their user profile or not
                                    // if not, leave it as default (2 minutes)
                                    if (localStorage.getItem('refreshTimer') === null)
                                        refreshDate = addMinutes(refreshDate, 2);
                                    // Otherwise check that the user hasn't disabled the refresh timer. If not, set refreshDate to the number of minutes the user has specified in their User Profile
                                    else if(localStorage.getItem('refreshTimer') !== 'never')
                                        refreshDate = addMinutes(refreshDate, parseInt(localStorage.getItem('refreshTimer') as string))
                                }
                                dispatch({ type: 'CHANGES_RECEIVE_DATA', changes: data.dtos, filterLists: data.filterLists, dataType, refreshDate });
                                if(data.dtos.length === 0)
                                    Notify({ severity: 'info', message: 'No data available.' });
                            });
                    }
                    else {
                        (response.json())
                            .then(data => {
                                dispatch({ type: 'CHANGES_REQUEST_DATA', dataType });
                                Notify({ severity: 'error', message: data.message });
                            })
                    }
        });

            dispatch({ type: 'CHANGES_REQUEST_DATA', dataType });
            Notify({ severity: 'info', message: 'Loading data' });
        }
    },

    createChange: (dtos: [], finalize: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.submissions && dtos !== undefined) {

            fetch(`api/change/create`,
                {
                    method: 'POST',
                    body: JSON.stringify(dtos),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json; charset=utf-8"
                    }
                })
                .then(response => {
                    if (response.status === 200) {
                        (response.json())
                            .then(data => {
                                dispatch({ type: 'CHANGES_CREATE_CHANGE' });
                                Notify({ severity: 'success', message: data.message });
                                finalize();
                            });
                    }
                    else {
                        (response.json())
                            .then(data => {
                                Notify({ severity: 'error', message: data.message })
                            });
                    }
                })
        }
    },

    refreshData: (dataType: string, forceRefresh?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        // force refresh of data based on this token existing
        // this indicates that a submission has been created from the Model Points tab (state is based on DataPoints.ts 
        // and that the submission state(state based on Submissions.ts) needs to be reloaded.
        if (actionCreators.getToken('reloadData') !== "") {
            forceRefresh = true;
            localStorage.removeItem('reloadData');
        }

        if (appState && appState.submissions && (appState.submissions.refresh || forceRefresh)) {
            Notify({ severity: 'info', message: 'Refreshing data' });
            dispatch({ type: 'CHANGES_REFRESH_DATA'});
        }
    },

    updateData: (dtos: [], finalize: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.submissions && dtos !== undefined) {
            if (dtos.length > 0) {
                fetch(`api/change/update`, {
                    method: 'POST',
                    body: JSON.stringify(dtos),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => {
                        if (response.status === 200) {
                            //success! reload changes. notify user
                            (response.json())
                                .then(data => {
                                    dispatch({ type: 'CHANGES_UPDATE_DATA' });
                                    Notify({ severity: 'success', message: data.message })
                                    finalize();
                                });
                        }
                        else {
                            //failure. notify user.
                            (response.json())
                                .then(data => {
                                    Notify({ severity: 'error', message: data.message })
                                });
                        }
                    })
            }
            else
                Notify({ severity: 'info', message: 'No updates found for this submission.' })
        }
    },

    cancelChange: (dto: {}, finalize: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.submissions && dto !== undefined) {
            fetch(`api/change/cancel`, {
                method: 'POST',
                body: JSON.stringify(dto),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        //success! reload changes. notify user
                        (response.json())
                            .then(data => {
                                dispatch({ type: 'CHANGES_CANCEL_CHANGE' });
                                Notify({ severity: 'success', message: data.message })
                                finalize();
                            });
                    }
                    else {
                        //failure. notify user.
                        (response.json())
                            .then(data => {
                                Notify({ severity: 'error', message: data.message })
                            });
                    }
                })
        }
    },

    // initiates approval process for submission and subsequent approval procedures
    approveChange: (dto: {}, finalize: any): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();

        if (appState && appState.submissions && dto !== undefined) {
            fetch(`api/change/approve`, {
                method: 'POST',
                body: JSON.stringify(dto),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        //success! reload changes. notify user
                        (response.json())
                            .then(data => {
                                if (data.refresh)
                                    localStorage.setItem('dataRefresh', 'true');
                                dispatch({ type: 'CHANGES_APPROVE_CHANGE' });
                                Notify({ severity: 'success', message: data.message })
                                finalize();
                            });
                    }
                    else {
                        //failure. notify user.
                        (response.json())
                            .then(data => {
                                Notify({ severity: 'error', message: data.message })
                            });
                    }
                })
        }
    },

    // initiates rejection procedure for submission
    rejectChange: (dto: {}, finalize: any): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();

        if (appState && appState.submissions && dto !== undefined) {
            fetch(`api/change/reject`, {
                method: 'POST',
                body: JSON.stringify(dto),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        //success! reload changes. notify user
                        (response.json())
                            .then(data => {
                                dispatch({ type: 'CHANGES_REJECT_CHANGE' });
                                Notify({ severity: 'success', message: data.message })
                                finalize();
                            });
                    }
                    else {
                        //failure. notify user.
                        (response.json())
                            .then(data => {
                                Notify({ severity: 'error', message: data.message })
                            });
                    }
                })
        }
    },

    // passes user supplied import file to back-end for processing and then returns generated array of objects to finalizeImport callback function
    importData: (file: FormData, finalizeImport: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.submissions && file !== undefined) {
            fetch(`api/change/import`, {
                method: 'POST',
                body: file
            })
                .then(response => {
                    if (response.status === 200) {
                        //success! reload changes. notify user
                        (response.json())
                            .then(data => {
                                Notify({ severity: 'success', message: data.message })
                                finalizeImport(data.dtos);
                            });
                    }
                    else {
                        //failure. notify user.
                        (response.json())
                            .then(data => {
                                Notify({ severity: 'error', message: data.message })

                                // If there are validation errors, display each error message
                                if (data.errors && Array.isArray(data.errors)) {
                                    data.errors.forEach((error: string) => {
                                        Notify({ severity: 'error', message: error });
                                    });
                                }
                            });
                    }
                })
        }
    },

    // retrieves token from localStorage
    getToken: (id: string) => {

        let token = localStorage.getItem(id) || "";

        if (token !== "" && typeof token !== 'undefined' && token !== null)
            return JSON.parse(token);
        else
            return "";
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: SubmissionState = { data: [], isLoading: false, isLoaded: false, refresh: false, filterLists: [], archiveData: [], archiveDataLoaded: false, archiveDataLoading: false, archiveFilterLists: [], pendingData: [], pendingDataLoading: false, pendingDataLoaded: false, pendingFilterLists: [], refreshDate: new Date(), pendingRefreshDate: new Date(), archiveRefreshDate: new Date() };

export const reducer: Reducer<SubmissionState> = (state: SubmissionState | undefined, incomingAction: Action): SubmissionState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CHANGES_REQUEST_DATA':
            if (action.dataType === 'current') {
                return {
                    ...state,
                    isLoaded: false,
                    isLoading: true
                };
            }
            else if (action.dataType === 'archive') {
                return {
                    ...state,
                    archiveDataLoaded: false,
                    archiveDataLoading: true
                };
            }
            else {
                return {
                    ...state,
                    pendingDataLoaded: false,
                    pendingDataLoading: true
                };
            }
        case 'CHANGES_RECEIVE_DATA':
            if (action.dataType === 'current') {
                return {
                    ...state,
                    data: action.changes,
                    filterLists: action.filterLists,
                    isLoading: false,
                    isLoaded: true,
                    refreshDate: action.refreshDate
                };
            }
            else if (action.dataType === 'archive') {
                return {
                    ...state,
                    archiveData: action.changes,
                    archiveFilterLists: action.filterLists,
                    archiveDataLoading: false,
                    archiveDataLoaded: true,
                    archiveRefreshDate: action.refreshDate
                };
            }
            else {
                return {
                    ...state,
                    pendingData: action.changes,
                    pendingFilterLists: action.filterLists,
                    pendingDataLoading: false,
                    pendingDataLoaded: true,
                    pendingRefreshDate: action.refreshDate
                };
            }
        case 'CHANGES_CREATE_CHANGE':
            return {
                ...state,
                data: [],
                refresh: true,
            };
        case 'CHANGES_UPDATE_DATA':
                return {
                    ...state,
                    data: [],
                    refresh: true,
            };
        case 'CHANGES_REFRESH_DATA':
            //if (action.dataType === 'current') {
                return {
                    ...state,
                    isLoaded: false,
                    isLoading: false,
                    data: [],
                    filterLists: [],
                    archiveDataLoaded: false,
                    archiveDataLoading: false,
                    archiveData: [],
                    archiveFilterLists: [],
                    pendingDataLoaded: false,
                    pendingDataLoading: false,
                    pendingData: [],
                    pendingFilterLists: [],
                    refresh: false
                };
            //}
            //else if (action.dataType === 'archive') {
            //    return {
            //        ...state,
            //        archiveDataLoaded: false,
            //        archiveDataLoading: false,
            //        archiveData: [],
            //        archiveFilterLists:[],
            //        refresh: false
            //    };
            //}
            //else {
            //    return {
            //        ...state,
            //        pendingDataLoaded: false,
            //        pendingDataLoading: false,
            //        pendingData: [],
            //        pendingFilterLists: [],
            //        refresh: false
            //    };
            //}
        case 'CHANGES_CANCEL_CHANGE':
            return {
                ...state,
                data: [],
                refresh: true
            }
        case 'CHANGES_APPROVE_CHANGE':
            return {
                ...state,
                data: [],
                refresh: true
            }
        case 'CHANGES_REJECT_CHANGE':
            return {
                ...state,
                data: [],
                refresh: true
            }
    }
    return state;
};