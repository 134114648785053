import React, { forwardRef } from 'react';
import { TextField } from '@mui/material';

const Input = forwardRef((props, ref) => {
    const { name, label, value, onChange, error = null, ...rest } = props;

    return (
        <TextField
            variant="outlined"
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            inputRef={ref}  // Use inputRef to forward the ref to the actual TextField input
            {...(error && { error: true, helperText: error })}
            {...rest}
        />
    );
});

export default Input;