import { Grid, Checkbox, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm, Form } from '../common/useForm.js'
import Controls from '../controls/Controls';
import Companies from '../Companies.tsx'
import { isNullOrUndefined } from 'util';
import Scada from '../Scada.tsx';

const tsOptions = [
    { id: 'NOTS', title: 'NOTS' },
    { id: 'TS', title: 'TS' },
    { id: '', title: 'N/A' }
];
const qOptions = [
    { id: 'NOQ', title: 'NOQ' },
    { id: 'Q', title: 'Q' },
    { id: '', title: 'N/A' }
];

const typeOptions = [
    { id: 'REAL32', title: 'REAL32' },
    { id: 'STAT16', title: 'STAT16' }
];

/**
 * Displays the form structure and holds all applicable validation for the form fields
 * for all submission items created/displayed on a submission
 * @param {any} props
 */

export default function SubmissionsForm(props) {
    
    const { recordForEdit, user, order, addDetails } = props;
    const [formDisabled, setFormDisabled] = useState(false);
    const [checked, setChecked] = useState(false);
    const submissionOptions =
        (!isNullOrUndefined(recordForEdit) && (recordForEdit.remoteName === recordForEdit.createdByRemote || (user.isAdmin || user.isModeler))) ?
            [
                { id: 'ADD', title: 'ADD' },
                { id: 'DELETE', title: 'DELETE' },
                { id: 'PERMISSION_GRANT', title: 'PERMISSION_GRANT' },
                { id: 'PERMISSION_REMOVE', title: 'PERMISSION_REMOVE' },
                { id: 'REPLACE', title: 'REPLACE' }
            ] :
            isNullOrUndefined(recordForEdit) ?
                [
                    { id: 'ADD', title: 'ADD' },
                    { id: 'DELETE', title: 'DELETE' },
                    { id: 'PERMISSION_GRANT', title: 'PERMISSION_GRANT' },
                    { id: 'PERMISSION_REMOVE', title: 'PERMISSION_REMOVE' },
                    { id: 'REPLACE', title: 'REPLACE' }
                ] :
                [
                    { id: 'PERMISSION_GRANT', title: 'PERMISSION_GRANT' },
                    { id: 'PERMISSION_REMOVE', title: 'PERMISSION_REMOVE' }
                ]

    const initialValues = !isNullOrUndefined(recordForEdit) ?
        {
            submissionType: recordForEdit.submissionType,
            status: recordForEdit.status === '' ? '' : recordForEdit.status,
            dataItem: isNullOrUndefined(recordForEdit.dataItem) ? '' : recordForEdit.dataItem,
            dataItemOld: isNullOrUndefined(recordForEdit.dataItemOld) ? '' : recordForEdit.dataItemOld,
            deviceId: isNullOrUndefined(recordForEdit.deviceId) ? '' : recordForEdit.deviceId,
            deviceType: isNullOrUndefined(recordForEdit.deviceType) ? '' : recordForEdit.deviceType,
            interconnect: recordForEdit.interconnect,
            remoteName: recordForEdit.remoteName,//(user.isAdmin || user.isModeler) ? recordForEdit.remoteName : user.company,
            areaEms: isNullOrUndefined(recordForEdit.areaEms) ? '' : recordForEdit.areaEms,
            emsStation: isNullOrUndefined(recordForEdit.emsStation) ? '' : recordForEdit.emsStation,
            type: isNullOrUndefined(recordForEdit.type) ? '' : recordForEdit.type,
            qualityStatus: isNullOrUndefined(recordForEdit.qualityStatus) ? '' : recordForEdit.qualityStatus,
            tsStatus: isNullOrUndefined(recordForEdit.tsStatus) ? '' : recordForEdit.tsStatus,
            unitMeas: isNullOrUndefined(recordForEdit.unitMeas) ? '' : recordForEdit.unitMeas,
            remoteAddOrRemove: isNullOrUndefined(recordForEdit.remoteAddOrRemove) ? [] : recordForEdit.remoteAddOrRemove,
            comments: recordForEdit.comments,
            createdByRemote: recordForEdit.createdByRemote,
            createdByDisplayName: recordForEdit.createdByDisplayName,
            createdBy: recordForEdit.createdBy,
            detailId: recordForEdit.detailId,
            parentId: recordForEdit.parentId,
            order: recordForEdit.order,
            updatedBy: user.userName,
            isDeleted: false,
            insertedWhen: isNullOrUndefined(recordForEdit.insertedWhen) ? new Date() : recordForEdit.insertedWhen ,
            usses: isNullOrUndefined(recordForEdit.usses) ? false : recordForEdit.usses
        } :
        {
            submissionType: '',
            status: '',
            dataItem: '',
            dataItemOld: '',
            deviceId: '',
            deviceType: '',
            interconnect: '',
            remoteName: (user.isAdmin || user.isModeler) ? '' : user.company,
            areaEms: '',
            emsStation: '',
            type: '',
            qualityStatus: '',
            tsStatus: '',
            unitMeas: '',
            remoteAddOrRemove: [],
            comments: '',
            createdByRemote: user.company,
            createdByDisplayName: user.fullName,
            createdBy: user.userName,
            detailId: 0,
            parentId: 0,
            order: order,
            updatedBy: user.userName,
            isDeleted: false,
            insertedWhen: new Date(),
            usses: false
        };

    // Identifies if the selected item from source js file has been updated (selection occurred) and populates the intial values for the pop up form with the selected item
    useEffect(() => {
        setValues({
            ...initialValues
        })
        addDetails(values);
        setChecked(initialValues.usses);
    }, [recordForEdit])

    const validate = (fieldValues = values) => {
        let format = /[!@#%^&*()+\-=\[\]{};':"\\ |,.<>\/?]+/;
        let temp = { ...errors }

        // data item field will always need to be populate regardless of submission type
        if ('dataItem' in fieldValues) {
            temp.dataItem = fieldValues.dataItem.length < 33 ? '' : 'Object IDs must be 32 characters or less.'
            temp.dataItem += !format.test(fieldValues.dataItem) ? '' : 'Object IDs only allow $ and _ special characters.'
            temp.dataItem += !!(fieldValues.dataItem) ? '' : 'This field is required.'
        }

        if ('deviceId' in fieldValues) {
            temp.deviceId = fieldValues.deviceId.length < 33 ? '' : 'Device Names must be 32 characters or less.'
        }

        if ('unitMeas' in fieldValues) {
            temp.unitMeas = fieldValues.unitMeas.length < 11 ? '' : 'Measurement Type cannot exceed 10 characters.'
        }

        // submission type field will always need to be populate regardless of submission type
        if ('submissionType' in fieldValues) {
            temp.submissionType = !!(fieldValues.submissionType) ? '' : 'This field is required.'

            if (fieldValues.submissionType === 'ADD') {

                // perform the following validation only if the submissionType is add.
                if ('id' in fieldValues)
                    temp.id = fieldValues.id ? '' : 'This field is required.'
                if ('type' in fieldValues)
                    temp.type = fieldValues.type ? '' : 'This field is required.'
                //if ('unitMeas' in fieldValues) {
                //    temp.unitMeas = fieldValues.unitMeas.length < 11 ? '' : 'Measurement Type cannot exceed 10 characters.'
                //}
            }
            else {
                temp.id = ''
                temp.type = ''
                temp.emsStation = ''
                temp.deviceType = ''
                temp.deviceId = ''
                temp.unitMeas = ''
            }

            // try to retain value of dataItem or dataItemOld and switch it to the other field depending on if the user
            // changes their submission type for convenience
            if (fieldValues.submissionType === 'REPLACE') {
                if (values.dataItem !== '' && (isNullOrUndefined(values.dataItemOld) || values.dataItemOld === '')) {
                    setValues({
                        ...values,
                        ...fieldValues,
                        dataItemOld: values.dataItem,
                        dataItem: ''
                    })
                    fieldValues.dataItem = ''
                    temp.dataItem = ''
                }

                // perform the following validation only if the submissionType is replace
                if ('dataItemOld' in fieldValues) {
                    temp.dataItemOld = fieldValues.dataItemOld.length < 33 ? '' : 'Object IDs must be 32 characters or less.'
                    temp.dataItemOld += !format.test(fieldValues.dataItemOld) ? '' : 'Object IDs only allow $ and _ special characters.'
                    temp.dataItemOld = fieldValues.dataItemOld ? '' : 'This field is required.'
                }
            }
            else
                temp.dataItemOld = ''

            if (fieldValues.submissionType.includes('PERMISSION')) {
                // perform the following validation only if the submissionType is permission_grant or permission_remove
                if ('remoteAddOrRemove' in fieldValues)
                    temp.remoteAddOrRemove = fieldValues.remoteAddOrRemove.length !== 0 ? '' : 'You must select at least one Remote from the list.'
            }
            else
                temp.remoteAddOrRemove = ''
            // try to retain value of dataItem or dataItemOld and switch it to the other field depending on if the user
            // changes their submission type for convenience
            if (fieldValues.submissionType === 'DELETE' || fieldValues.submissionType.includes('PERMISSION') || fieldValues.submissionType === 'ADD') {
                if (values.dataItemOld !== '') {
                    setValues({
                        ...values,
                        ...fieldValues,
                        dataItem: (isNullOrUndefined(values.dataItemOld) || values.dataItemOld === '') ? values.dataItem : values.dataItemOld,
                        dataItemOld: ''
                    })
                    fieldValues.dataItemOld = ''
                    temp.dataItemOld = ''
                }
            }
        }

        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === '')
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleChange,
        resetForm
    } = useForm(initialValues, true, validate);

    const handleSubmit = e => {

        e.preventDefault();
        let item = {
            ...values,
            insertedWhen: new Date()
        };
        // use callback to pass updated values to Submissions to retain for overall submission item list
        addDetails(item)
    }

    // called by Scada.js when the Apply Mappings button is clicked. Applies the SPP EMS SCADA
    // values the user has selected in the Scada popup window to the current submission item.
    const setScadaMappings = (scadaValues, clearData) => {
        let item = {
            ...values,
            ...scadaValues,
            usses: true,
            insertedWhen: new Date()
        };
        setChecked(true);
        setValues({
            ...item
        });
        // use callback to Scada clearData to close SCADA window and clear all stored data
        clearData();
        // use callback to pass updated values to Submissions to retain for overall submission item list
        addDetails(item);
    }

    const updateDetails = (e, values) => {
        let { name, value } = e.target;
        let item;
        if (name === 'emsStation' || name === 'deviceType' || name === 'deviceId' || name === 'unitMeas') {
            setChecked(false);
            item = {
                ...values,
                [name]: value,
                insertedWhen: new Date(),
                usses: false
            };
        }
        else {
            item = {
                ...values,
                [name]: value,
                insertedWhen: new Date()
            };
        }
        // use callback to pass updated values to Submissions to retain for overall submission item list
        addDetails(item)
    }

    const handleDelete = (values) => {
        let item = { ...values, isDeleted: true, insertedWhen: new Date() };
        // use callback to pass updated values to Submissions to retain for overall submission item list
        addDetails(item)
    }
    

    const disabled = (((user.userName === values.createdBy || user.company === values.createdByRemote) && (values.status === 'DRAFT' || values.status === '')) || (user.isModeler || user.isAdmin)) ? false : true

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                {(values.status === 'DRAFT' || values.status === '') ?
                    <Controls.Select name='submissionType'
                        label='Submission Type'
                        required
                        disabled={disabled || formDisabled}
                        value={values.submissionType}
                        options={submissionOptions}
                        onChange={(e) => { handleChange(e); updateDetails(e, values)}}
                        error={errors.submissionType}
                        />
                    :
                    <Controls.Input name='submissionType'
                        label='Submission Type'
                        disabled
                        value={values.submissionType}
                        onChange={(e) => { handleChange(e); updateDetails(e, values); }}
                        error={errors.submissionType}
                    />
                }
                {!values.status === '' ?
                    <Controls.Input name='status'
                        label='Status'
                        disabled
                        value={values.status}
                        onChange={(e) => { handleChange(e); updateDetails(e, values); }}
                        error={errors.status}
                    />
                    : null}
                <Controls.Input name='dataItem'
                    label='Object ID'
                    value={values.dataItem}
                    onChange={(e) => { handleChange(e); updateDetails(e, values); }}
                    error={errors.dataItem}
                    disabled={disabled || formDisabled}
                />
                {values.submissionType === 'REPLACE' ?
                    <Controls.Input name='dataItemOld'
                        label='Old Object ID'
                        value={values.dataItemOld}
                        onChange={(e) => { handleChange(e); updateDetails(e, values); }}
                        disabled={disabled || formDisabled}
                    />
                    : null
                }
                {(values.submissionType.toString() === 'ADD') ?
                    <>
                        <Controls.Select name='type'
                            label='Type'
                            value={values.type}
                            options={typeOptions}
                            onChange={(e) => { handleChange(e); updateDetails(e, values); }}
                            error={errors.type}
                            disabled={disabled || formDisabled}
                        />
                        <Controls.Input name='emsStation'
                            label='Station Name'
                            value={values.emsStation}
                            onChange={(e) => { handleChange(e); updateDetails(e, values); }}
                            error={errors.emsStation}
                            disabled={disabled || formDisabled}
                        />
                        <Controls.Input name='deviceType'
                            label='Device Type'
                            value={values.deviceType}
                            onChange={(e) => { handleChange(e); updateDetails(e, values); }}
                            error={errors.deviceType}
                            disabled={disabled || formDisabled}
                        />
                        <Controls.Input name='deviceId'
                            label='Device Name'
                            value={values.deviceId}
                            onChange={(e) => { handleChange(e); updateDetails(e, values); }}
                            error={errors.deviceId}
                            disabled={disabled || formDisabled}
                        />
                        <Controls.Input name='unitMeas'
                            label='Measurement Type '
                            value={values.unitMeas}
                            onChange={(e) => { handleChange(e); updateDetails(e, values); }}
                            error={errors.unitMeas}
                            disabled={disabled || formDisabled}
                        />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', width: '80%' }}>
                            {(user.isAdmin || user.isModeler) ?
                                <Tooltip title='User Submitted SPP EMS SCADA'>
                                    <span>
                                    <Checkbox name='usses' label='' checked={checked} disabled={true} />
                                        </span>
                                </Tooltip>
                                : null
                            }
                            <Scada recordForEdit={values} disabled setScadaMappings={setScadaMappings} />
                        </div>
                    </> : null}
                {(values.submissionType.toString() === 'ADD' || values.submissionType.toString().includes('PERMISSION')) ?
                    <Companies name='remoteAddOrRemove'
                        label={values.submissionType.toString() === 'PERMISSION_REMOVE' ? 'Remove Permissions' : 'Permissions'}
                        disabled={disabled}
                        onChange={(e) => { handleChange(e); updateDetails(e, values); }}
                        value={values.remoteAddOrRemove}
                        multiple
                        error={errors.remoteAddOrRemove}
                    />
                    : null}
                {((user.isAdmin || user.isModeler) || values.submissionType.toString().includes('PERMISSION')) ?
                    <Companies name='remoteName'
                        label='Remote'
                        onChange={(e) => { handleChange(e); updateDetails(e, values); }}
                        value={values.remoteName}
                        error={errors.remoteName}
                        disabled={disabled || formDisabled}
                    /> : null
                }
                <Controls.Select name='qualityStatus'
                    label='Quality'
                    value={values.qualityStatus}
                    options={qOptions}
                    onChange={(e) => { handleChange(e); updateDetails(e, values); }}
                    disabled={disabled || formDisabled}
                />
                <Controls.Select name='tsStatus'
                    label='Timestamp'
                    value={values.tsStatus}
                    options={tsOptions}
                    onChange={(e) => { handleChange(e); updateDetails(e, values); }}
                    disabled={disabled || formDisabled}
                />
                <Controls.Input name='createdBy'
                    label='Requested By'
                    disabled
                    value={values.createdByDisplayName + ' - ' + values.createdByRemote}
                />
                <Controls.Input name='comments'
                    label='Comments'
                    multiline
                    value={values.comments}
                    onChange={(e) => { handleChange(e); updateDetails(e, values); }}
                    disabled={disabled || formDisabled}
                />
            </Grid>
            <div>
                <Controls.Button
                    variant='contained'
                    color='primary'
                    onClick={() => validate(values)}
                    text='Validate'
                    title='Perform validation checks on fields.'
                    disabled={disabled || formDisabled}
                />
                <Controls.Button
                    variant='contained'
                    color='default'
                    onClick={() => { resetForm(); setFormDisabled(false) }}
                    text='Reset'
                    title='Undo changes to submission.'
                    disabled={disabled}
                />
                <Controls.Button
                    variant='contained'
                    color='secondary'
                    onClick={() => { setFormDisabled(!formDisabled); handleDelete(values); }}
                    text='Delete'
                    title='Mark item to be deleted.'
                    disabled={disabled || formDisabled}
                />
            </div>
        </Form>
        )
}