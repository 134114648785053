import { useState, useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, Grid } from '@mui/material';
import Popup from '../controls/Popup.js';
import SubmissionsForm from './SubmissionsForm.js';
import useTable from '../common/Table.js'
import { format } from 'date-fns';
import * as Login from '../../store/Login.ts'
import DropDown from '../controls/DropDown.js';
import Controls from '../controls/Controls';
import { useForm } from '../common/useForm.js';
import { isNullOrUndefined } from 'util';
import PublishIcon from '@mui/icons-material/Publish';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { CsvBuilder } from 'filefy';

const headCells = [
    { id: 'id', label: 'ID' },
    { id: 'status', label: 'Status', filterComponent: true},
    { id: 'description', label: 'Description' },
    { id: 'createdBy', label: 'Created By', hidden: true },
    { id: 'createdByDisplayName', label: 'Created By' },
    { id: 'createdOn', label: 'Created On', render: rowData => rowData.createdOn === null ? null : format(new Date(rowData.createdOn), 'MM/dd/yy hh:mm:ss a') },
    { id: 'updatedBy', label: 'Updated By', hidden: true },
    { id: 'updatedByDisplayName', label: 'Updated By' },
    { id: 'updatedOn', label: 'Updated On', render: rowData => rowData.updatedOn === null ? null : format(new Date(rowData.updatedOn), 'MM/dd/yy hh:mm:ss a') },
];

const downloadCells = [
    { id: 'submissionType', label: 'Submission Type' },
    { id: 'dataItemOld', label: 'Old Object ID' },
    { id: 'dataItem', label: 'Object ID' },
    { id: 'type', label: 'Type' },
    { id: 'qualityStatus', label: 'Quality' },
    { id: 'tsStatus', label: 'Timestamp' },
    { id: 'emsStation', label: 'Station' },
    { id: 'deviceType', label: 'Device Type' },
    { id: 'deviceId', label: 'Device Name' },
    { id: 'unitMeas', label: 'Measurement Type' },
    { id: 'remoteAddOrRemove', label: 'Permit' }
];

/**
 * This fiie is used by ArchiveSubmissions.tsx, PendingSubmissions.tsx, and Submissions.tsx
 * to pass to Table.js the appropriate columns to display on the page,
 * make available for exports, and applicable actions
 * @param {any} props
 */

export default function Submissions(props) {
    const [openPopup, setOpenPopup] = useState(false);
    const [verifyPopup, setVerifyPopup] = useState(false);
    const [uploadPopup, setUploadPopup] = useState(false);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [changeItems, setChangeItems] = useState([]);
    const [changeItemDetails, setChangeItemDetails] = useState([]);
    const [title, setTitle] = useState();
    const [uploadFile, setUploadFile] = useState(null);
    const [description, setDescription] = useState();
    const [verifyScadaPopup, setVerifyScadaPopup] = useState(false);
    const [helpPopup, setHelpPopup] = useState(false);

    const user = Login.actionCreators.getUser();
    const { formValidate, errorsList, setErrorsList, resetForm } = useForm();
    const disabled = ((recordForEdit !== null && recordForEdit.status === 'DRAFT') || ((!isNullOrUndefined(user) && (user.isAdmin || user.isModeler)))) ? false : true

    const { source } = props;
    const inputRef = useRef(null);

    // Establish interval to automatically trigger data refresh every 2 minutes. 
    // This is done to help keep users up-to-date on submission changes without having to manually refresh
    useEffect(() => {
        const interval = setInterval(() => {
            // checks every minute
            // only proceed if the user hasn't disabled their refresh timer via their User Profile
            if (localStorage.getItem('refreshTimer') !== 'never') {
                // Confirm that the current date/time has passed the set refreshDate. If so, trigger a refresh
                if (new Date() > source.refreshDate) {
                    props.refreshData(source.type, true);
                }
            }
        }, 60000);

        return () => clearInterval(interval);
    }, [source.refreshDate])

    const actions =
        (source.type === 'current') ?
            [
                {
                    icon: 'add',
                    tooltip: 'Create Submission',
                    isFreeAction: true,
                    hidden: user.isMpOnly ? true : false,
                    // Opens a blank submission window
                    onClick: (event) => {
                        setTitle('Create Submission')
                        openInPopupFromScratch()
                    }
                },
                {
                    icon: () => <PublishIcon />,
                    tooltip: 'Create Submission from Import',
                    isFreeAction: true,
                    hidden: user.isMpOnly ? true : false,
                    // Opens the Import Dialog defined near the bottom of this page
                    // so that the user may upload an import file containing
                    // all of the items they wish to create a change for
                    onClick: (event) => {
                        setUploadPopup(true);
                    }
                },
                {
                    icon: 'edit',
                    tooltip: 'Edit the selected submission',
                    position: 'row',
                    hidden: user.isMpOnly ? true : false,
                    onClick: (event, rowData) => {
                            setDescription(rowData.description);
                            setTitle('Update Submission')
                            setRecordForEdit(rowData);
                            openInPopup(rowData);
                    }
                },
                {
                    icon: 'refresh',
                    tooltip: 'Refresh data',
                    isFreeAction: true,
                    // Calls the refreshData method in Submissions.ts
                    onClick: () => {
                        props.refreshData(source.type, true)
                    }
                },
                {
                    icon: () => <HelpOutlineIcon />,
                    tooltip: 'Click to see page info and actions',
                    isFreeAction: true,
                    // Sets helpPopup to true, which is used
                    // to make the Help Dialog defined near the bottom of the page visible.
                    onClick: () => {
                        setHelpPopup(true)
                    }
                }
            ] :
            // limit available access to just refresh if this is the archive tab
            [
                {
                    icon: 'refresh',
                    tooltip: 'Refresh data',
                    isFreeAction: true,
                    // Calls the refreshData method in Submissions.ts
                    onClick: () => {
                        props.refreshData(source.type, true)
                    }
                },
                {
                    icon: () => <HelpOutlineIcon />,
                    tooltip: 'Click to see page info and actions',
                    isFreeAction: true,
                    // Sets helpPopup to true, which is used
                    // to make the Help Dialog defined near the bottom of the page visible.
                    onClick: () => {
                        setHelpPopup(true)
                    }
                }
            ];

    // table option info passed to Table.js to define functionality that should be available to the user
    const tableOptions = {
        filtering: true,
        selection: false,
        draggable: false,
        columnResizable: true,
        hideFilterIcons: true,
        search: source.type !== 'pending' ? true : false
    };

    // provides a blurb of actions to be performed on the page the user is viewing depending on the data type (current, pending, or archive)
    const TableDescription = () => {
        return (
            <div>
                {source.type === 'current' ?
                    <>
                        <p>This table displays existing submissions that are not yet implemented in the ICCP model.</p>
                        <ul style={{ listStyle: 'none' }}>
                            <li>View the contents of a submission by clicking the <KeyboardArrowRightIcon /> icon to the left of a submission in the table below.</li>
                            {!user.isMpOnly ?
                                <>
                                    <li>Submissions may be edited by clicking the <EditIcon /> icon to the left of a submission in the table below.</li>
                                    <li>Submissions may be created from scratch by clicking the <AddIcon /> icon in the top-right corner of this page.</li>
                                    <li>Submissions may be created from an import by using the <PublishIcon /> icon in the top-right corner of this page.</li>
                                </>
                                : null}
                            <li>Data can be refreshed by clicking the <RefreshIcon /> icon in the top-right corner of this page. Otherwise it is refreshed automaticallly every two minutes.</li>
                        </ul>
                    </>
                    :
                    source.type === 'pending' ?
                        <p>
                            This table displays pending submissions that have been created by you or have changes that impact your remote.
                            <br />
                            Data can be refreshed by clicking the <RefreshIcon /> icon to the right on this page.
                        </p>
                        :
                        <p>
                            This table displays archived submissions that have been implemented in the ICCP model.
                            Archived data is retained for five years and removed afterward.
                        </p>
                }
                <p>For a more in-depth overview and how-to examples, please refer to the following <a href={process.env.PUBLIC_URL + '/ICCP ONLINE SUBMISSION TOOL User Manual External.pdf'} download={'ICCP ONLINE SUBMISSION TOOL User Manual External.pdf'}>user guide</a>.</p>
            </div>
        );
    }

    // Creates a list of formatted items that are displayed in Popup further down
    const openInPopup = (item) => {
        addItems(item)
        setOpenPopup(true)
    }

    // Creates a single formatted, empty item that is displayed in Popup further down
    const openInPopupFromScratch = () => {
        addItem();
        setOpenPopup(true);
    }

    // Updates an existing list of items that are displayed in Popup further down
    const updateItems = (data) => {
        const items = (
           data.map((sd, index) => (
               <DropDown title={(sd.dataItem !== '' && sd.submissionType !== '') ? sd.dataItem + ' : ' + sd.submissionType : (sd.dataItem !== '' && sd.submissionType === '') ? sd.dataItem : `Entry ${index + 1}`} key={index} recordForEdit={sd}>
                    <SubmissionsForm create={create} user={user} order={index} recordForEdit={sd} addDetails={addDetails} />
                </DropDown>
           )));

        setChangeItems(items);
    }

    // creates dropdown menus for the items being loaded from a selected submission to the UI list before displaying to the user
    const addItems = (rowData) => {

        rowData.submissionDetails.forEach((sd, index) => {
            Object.assign(sd, {
                order: index,
                status: rowData.status
            });
        });

        const items = (
            rowData.submissionDetails.map((sd, index) => (
                <DropDown title={(sd.dataItem !== '' && sd.submissionType !== '') ? sd.dataItem + ' : ' + sd.submissionType : (sd.dataItem !== '' && sd.submissionType === '') ? sd.dataItem : `Entry ${index + 1}`} key={index} recordForEdit={sd}>
                    <SubmissionsForm create={create} user={user} order={index} recordForEdit={sd} addDetails={addDetails}/>
                </DropDown>
            )));

        setChangeItems(changeItems => changeItems.concat(items));
    }

    // creates a new dropdown menu for the item being added to the UI list and appends it to changeItems before reloading the view for the user
    const addItem = () => {
        let order = changeItems.length === undefined ? 0 : changeItems.length
        const item = (
            <DropDown title={`Entry ${changeItems.length + 1}`} key={order}>
                <SubmissionsForm create={create} user={user} recordForEdit={null} order={order} addDetails={addDetails} />
            </DropDown>
        );
       setChangeItems(changeItems => changeItems.concat(item))
    }

    // adds a copy of each item loaded per addItems or addItem so we retain all existing items on a submission, 
    // include any new items added, and record all changes made to them prior to sending the list
    // back to Submissions.ts per an action the user selects
    const addDetails = (values) => {
        // sort all objects in changeItemDetails in ascending order
        // since the Map(...) function for populating uniqueItemDetails below takes the entries in the list based on their order number
        // so by combining the ascending order based on the insert time and then using the item's order idea as their unique identifier
        // we ensure we always get the latest version of each item in the list regardless of where they're updated from (Popup or SubmissionsForm)
        changeItemDetails.sort((a, b) => {
            var aDate = new Date(a.insertedWhen);
            var bDate = new Date(b.insertedWhen);
            return aDate - bDate;
        })
        if (!isNullOrUndefined(values.value)) {
            // declare an empty array. This will be used to hold all unique entries of the change item details array. 
            // this is crucial to maintain a list of unique items if the user decides to update all item submission types
            // from the Popup (master) submission option. We sort the existing change item details list
            // to get the latest revision of each item in the list based on their order number and then update the submissionType of each entry.
            let uniqueItemDetails = [];
            // map the objects in changeItemDetails based on their order number. Takes the last instance of each duplicate item in the list based on the order number.
            uniqueItemDetails = [...new Map(changeItemDetails.sort((a, b) => a.insertedWhen - b.insertedWhen).map(c => [c['order'], c])).values()];

            let updatedChangeItemDetails = uniqueItemDetails.map(c => {
                // If user doing bulk update is trying to set the submissionType to ADD, DELETE, or REMOVE
                // and is not associated with the owning remote for the item being updated and is not an admin or modeler, don't update the submissionType
                if (values.name === 'submissionType' && (values.value === 'ADD' || values.value === 'DELETE' || values.value === 'REPLACE') && (c.remoteName !== c.createdByRemote && !(user.isAdmin || user.isModeler)))
                    return {
                        ...c,
                        insertedWhen: new Date()
                    }
                else {
                    return {
                        ...c,
                        [values.name]: values.value,
                        insertedWhen: new Date()
                    }
                }
            });

            setChangeItemDetails(updatedChangeItemDetails);
            updateItems(updatedChangeItemDetails);
        }
        else {
            setChangeItemDetails(changeItemDetails => [...changeItemDetails, values])
        }
    }

    // calls Submissions.ts createChange with all items on the current submission
    const create = () => {

        // sort all objects in changeItemDetails in ascending order
        // since the Map(...) function for populating uniqueItemDetails below takes the entries in the list based on their order number
        // so by combining the ascending order based on the insert time and then using the item's order idea as their unique identifier
        // we ensure we always get the latest version of each item in the list regardless of where they're updated from (Popup or SubmissionsForm)
        changeItemDetails.sort((a, b) => {
            var aDate = new Date(a.insertedWhen);
            var bDate = new Date(b.insertedWhen);
            return aDate - bDate;
        })

        let uniqueItemDetails = [];
        // map the objects in changeItemDetails based on their order number. Takes the last instance of each duplicate item in the list based on the order number.
        uniqueItemDetails = [...new Map(changeItemDetails.map(c => [c['order'], c])).values()];

        let finalChangeItemDetails = uniqueItemDetails.map(c => (
            {
                ...c,
                description: description
            }));

        props.createChange(finalChangeItemDetails, finalize)
    }

    // clears all data and closes all windows
    const finalize = () => {
        setOpenPopup(false);
        setVerifyPopup(false);
        setRecordForEdit(null);
        setChangeItemDetails([]);
        setChangeItems([]);
        resetForm();
        clearSelection();
        setUploadPopup(false);
        setUploadFile(null);
        setVerifyScadaPopup(false);
    }

    // calls Submissions.ts approveChange with all items on the current submission if they pass validation
    const approve = () => {
        setErrorsList([]);
        // run all entries in changeItemDetails (i.e. all items on the current submission)
        // through validation to ensure all required fields are populated correctly
        // the validated array below will contain true or false for each entry
        let validated = changeItemDetails.map(c => {
            if (formValidate(c))
                return true;
            else
                return false;
        });

        // check if any of the entries in validated are false. If so then validation for
        // at least one item on the submission failed and we won't kick off the approval
        // process yet.
        if (!validated.includes(false))
        {
            // check if any items on the submission are to ADD object IDs and don't have SCADA fields populated
            // if so, trigger the SCADA popup to have the user confirm if they want to advance the submission
            // in the approval process without mapping SCADA data. If they do this then SPP personnel
            // will be able to populate the data for them.
            if (recordForEdit.status === 'DRAFT') {
                let missingScadaData = changeItemDetails.map(c => {
                    if (c.submissionType === 'ADD') {
                        if (c.emsStation === '' ||
                            c.deviceType === '' ||
                            c.deviceId === '' ||
                            c.unitMeas === '') {
                            return c;
                        }
                    }
                }).filter(s => s !== undefined)

                if (missingScadaData.length !== 0)
                    setVerifyScadaPopup(true);

                else {
                    let finalRecordForEdit = {
                        ...recordForEdit,
                        updatedBy: user.userName,
                        adminComments: recordForEdit.adminComments
                    };
                    props.approveChange(finalRecordForEdit, finalize);
                }

            }
            // no items on the submission were to ADD oject IDs or they were but SCADA fields were populated, 
            // so proceed as normal
            else {
                let finalRecordForEdit = {
                    ...recordForEdit,
                    updatedBy: user.userName,
                    adminComments: recordForEdit.adminComments
                };
                props.approveChange(finalRecordForEdit, finalize);
            }
        }
        
    }

    // triggered by clicking Yes on SCADA Confirmation popup. Calls Submisisons.ts approveChange with all items on the current submission
    const approveWOScadaData = () => {

        let finalRecordForEdit = {
            ...recordForEdit,
            updatedBy: user.userName,
            adminComments: recordForEdit.adminComments
        };

        props.approveChange(finalRecordForEdit, finalize);
    }

    const reject = () => {
        let finalRecordForEdit = {
            ...recordForEdit,
            updatedBy: user.userName,
            adminComments: recordForEdit.adminComments
        };
        props.rejectChange(finalRecordForEdit, finalize);
    }

    // calls Submissions.ts updateData with all items on the current submission
    const update = () => {
        // call Changes.ts and pass it the finalize function so it can
        // callback to finalize with the knowledge of resetForm and only close the
        // submission window on a successful update attempt

        // sort all objects in changeItemDetails in ascending order
        // since the Map(...) function for populating uniqueItemDetails below takes the entries in the list based on their order number
        // so by combining the ascending order based on the insert time and then using the item's order idea as their unique identifier
        // we ensure we always get the latest version of each item in the list regardless of where they're updated from (Popup or SubmissionsForm)
        changeItemDetails.sort((a, b) => {
            var aDate = new Date(a.insertedWhen);
            var bDate = new Date(b.insertedWhen);
            return aDate - bDate;
        })

        let uniqueItemDetails = [];
        // map the objects in changeItemDetails based on their order number. Takes the last instance of each duplicate item in the list based on the order number.
        uniqueItemDetails = [...new Map(changeItemDetails.map(c => [c['order'], c])).values()];

        setChangeItemDetails(uniqueItemDetails);
        // loop through items really quick and append parentId to any new entries.
        //const finalChangeItemDetails = changeItemDetails.map(i => (
        const finalChangeItemDetails = uniqueItemDetails.map(i => (
            {
                ...i,
                updatedBy: user.userName,
                description: description,
                parentId: recordForEdit.id
            }
        ));
        props.updateData(finalChangeItemDetails, finalize);
    }

    // calls Submissions.ts cancelChange to cancel the current submission
    const cancel = () => {
        let finalRecordForEdit = {
            ...recordForEdit,
            updatedBy: user.userName
        };
        props.cancelChange(finalRecordForEdit, finalize)
    }

    const handleChange = (e) => {
        let { value } = e.target

        setDescription(value);
    }

    // called after a successful import from the source.importData method with the import items
    const finalizeImport = (items) => {
        
        const finalItems = (
            items.map((sd, index) => (
                sd.order = index,
                <DropDown title={(sd.dataItem !== '' && sd.submissionType !== '') ? sd.dataItem + ' : ' + sd.submissionType : (sd.dataItem !== '' && sd.submissionType === '') ? sd.dataItem : `Entry ${index + 1}`} key={index}>
                    <SubmissionsForm create={create} user={user} order={index} recordForEdit={sd} addDetails={addDetails} />
                </DropDown>
            )));
        setTitle('Create Submission');
        setChangeItems(changeItems => changeItems.concat(finalItems));
        setOpenPopup(true);
        setUploadPopup(false);
    }

    // called when the user chooses to import a file
    const fileUpload = () => {
        const formData = new FormData();

        formData.append("formFile", uploadFile);
        formData.append("fileName", user.userName + '_' + format(new Date(), 'MMddyy_hhmmss'));
        formData.append("createdBy", user.userName)

        props.importData(formData, finalizeImport);
        
        // reset uploadFile and text field after import attempt
        if (inputRef.current) {
            inputRef.current.value = "";
            }
        setUploadFile(null)
    }

    // called when user clicks Download Data button in Popup.js
    const download = () => {
        // use recordForEdit to set filename
        // and then use changeItemDetails to export the data items to the csv file
        const fileName = 'ICCPST_submission_' + recordForEdit.id;
        const builder = new CsvBuilder(fileName + '.csv');

        builder.setColumns(downloadCells.map(c => c.label)).addRows(changeItemDetails.map(item => downloadCells.map(c => item[c.id]))).exportFile();
    }
    
    const {
        MtlTable,
        clearSelection
    } = useTable(props.source, headCells, actions, tableOptions, null, true);

    return (
        <Grid container>
            <Grid item xs={12}>
                <MtlTable />
            </Grid>
            <Popup
                title={title}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                addItem={addItem}
                finalize={finalize}
                addDetails={addDetails}
                recordForEdit={recordForEdit}
                setVerifyPopup={setVerifyPopup}
                create={create}
                approve={approve}
                reject={reject}
                update={update}
                disabled={disabled}
                user={user}
                download={download}
            >
                {changeItems}
                <div>
                    <div>
                        <Controls.Input style={{ width: '100%', marginTop: '40px' }} name='description'
                            label='Description'
                            value={description}
                            onChange={handleChange}
                            multiline
                        />
                        {(recordForEdit !== null && recordForEdit.status.includes('PENDING')) ?
                            <Controls.Input style={{ width: '100%', marginTop: '10px' }} name='adminComments'
                                label='Approve/Reject Notes'
                                value={recordForEdit !== null ? recordForEdit.adminComments : ''}
                                onChange={handleChange}
                                multiline
                            /> :
                        null}
                    </div>
                    <div>
                        {errorsList.map(e => {
                                if (!(e.name==='' || isNullOrUndefined(e.name)))
                                return (
                                    <div style={{ color: 'red' }}>
                                        <b>{e.name}</b> failed validation. Please review the entry to confirm all necessary fields are populated.
                                    </div>
                                ) 
                        })}
                        {Object.keys(errorsList).length > 0 ?
                            <div>
                                <br />
                                After validating the entries, please click 'Update' to apply any changes and then click 'Initiate' to try again.
                            </div>
                            : null
                        }
                    </div>
                </div>
            </Popup>
            {/*Dialog explicitly to provide a confirmation if the user is cancelling a submission.*/}
            {/*This allows for us to cancel the action if the user accidentally clicks the button*/}
            <Dialog open={verifyPopup}>
                <DialogTitle>
                    Cancel Verification
                </DialogTitle>
                <DialogContent>
                    Are you sure you want to cancel this submission?
                    <br />
                    <br />
                    <Controls.Button
                        variant='contained'
                        color='primary'
                        onClick={cancel}
                        text='Yes'
                        title='Confirm action.'
                    />
                    <Controls.Button
                        variant='contained'
                        color='primary'
                        onClick={() => setVerifyPopup(false)}
                        text='No'
                        title='Cancel action.'
                        />
                </DialogContent>
            </Dialog>
            {/*Dialog explicitly to provide a confirmation if the user is submitting one or more items with submissionType = ADD.*/}
            {/*and the SCADA fields (DeviceId, DeviceName, EmsStation, UnitMeas) are empty. User may select Yes to proceed*/}
            {/*and SPP users will populate later. They may click No instead and be allowed to populate the data*/}
            <Dialog open={verifyScadaPopup}>
                <DialogTitle>
                    SCADA Verification
                </DialogTitle>
                <DialogContent>
                    One or more items on this submission are to add new Object IDs and are missing SCADA data.
                    <br />
                    Would you like to proceed with submitting this submission? SPP personnel can add the SCADA data later.
                    <br />
                    <br />
                    <Controls.Button
                        variant='contained'
                        color='primary'
                        onClick={approveWOScadaData}
                        text='Yes'
                        title='Proceed with action.'
                    />
                    <Controls.Button
                        variant='contained'
                        color='primary'
                        onClick={() => setVerifyScadaPopup(false)}
                        text='No'
                        title='Return to submission to make additional changes.'
                    />
                </DialogContent>
            </Dialog>
            {/*Dialog that pops up when a user clicks the import option on the Changes page*/}
            {/*This allows for users to create a submission based on a spreadsheet following*/}
            {/*the existing ICCP spreadsheet format ICCP Modeling has been sharing with entities*/}
            <Dialog open={uploadPopup}>
                <DialogTitle>
                    Import File
                </DialogTitle>
                <DialogContent>
                    <Controls.Input
                        type='file'
                        ref={inputRef}
                        onChange={(e) => setUploadFile(e.target.files[0])}
                    />
                    <br />
                    <br />
                    <p>Please use the <a href={process.env.PUBLIC_URL + '/ICCP Point Submission Template.xlsx'} download={'ICCP Point Submission Template.xlsx'}>following template</a> when using the import feature.</p>
                    <Controls.Button
                        variant='contained'
                        color='primary'
                        text='Upload'
                        title='Import file.'
                        onClick={fileUpload}
                    />
                    <Controls.Button
                        variant='contained'
                        color='primary'
                        text='Cancel'
                        title='Cancel action.'
                        onClick={() => setUploadPopup(false)}
                    />
                </DialogContent>
            </Dialog>
            {/*Dialog that pops up when a user clicks the help icon to get info about the page*/}
            <Dialog open={helpPopup}>
                <DialogTitle>
                    Page Details
                </DialogTitle>
                <DialogContent>
                    <TableDescription />
                    <br />
                    <br />
                    <Controls.Button
                        variant='contained'
                        color='primary'
                        text='OK'
                        title='Click to close window.'
                        onClick={() => setHelpPopup(false)}
                    />
                </DialogContent>
            </Dialog>
        </Grid>
    )
}
