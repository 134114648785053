import { Grid, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useState, useEffect } from 'react';
import useTable from '../common/Table.js'
import * as Login from '../../store/Login.ts'
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Controls from '../controls/Controls';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';

/**
 * This fiie is used by CurrentDataPoints.tsx and FutureDataPoints.tsx
 * to pass to Table.js the appropriate columns to display on the page,
 * make available for exports, and applicable actions
 * @param {any} props
 */

export default function DataPoints(props) {

    const { source } = props;
    const user = Login.actionCreators.getUser();
    const [helpPopup, setHelpPopup] = useState(false);
    // Establish interval to automatically trigger data refresh every 2 minutes. 
    // This is done to help keep users up-to-date on submission changes without having to manually refresh
    useEffect(() => {
        const interval = setInterval(() => {

            // checks every minute
            // only proceed if the user hasn't disabled their refresh timer via their User Profile
            if (localStorage.getItem('refreshTimer') !== 'never') {
                // Confirm that the current date/time has passed the set refreshDate. If so, trigger a refresh
                if (new Date() > source.refreshDate) {
                    props.refreshData(source.type, true);
                }
            }
        }, 6000)
        
        return () => clearInterval(interval);
    }, [source.refreshDate])

    const headCells = source.type === 'current' ?
        [
            { id: 'areaEms', label: 'EMS Area', filterComponent: true},
            { id: 'remoteName', label: 'Remote', filterComponent: true },
            { id: 'dataItem', label: 'DI' },
            { id: 'type', label: 'Type', sorting: false, filterComponent: true, isSearchable: false },
            { id: 'emsStation', label: 'Station', filterComponent: true },
            { id: 'deviceType', label: 'Device Type', filterComponent: true, },
            { id: 'deviceId', label: 'Device', filterComponent: true, },
            { id: 'unitMeas', label: 'Unit Meas', filterComponent: true, },
            { id: 'kv', label: 'KV Level', filterComponent: true, export: true },
            { id: 'qualityStatus', label: 'Q', sorting: false, filterComponent: true, isSearchable: false },
            { id: 'tsStatus', label: 'TS', sorting: false, filterComponent: true, isSearchable: false },
            { id: 'interconnect', label: 'Interconnect', sorting: false, filterComponent: true, isSearchable: false, export: true },
            { id: 'periodicity', label: 'Periodicity', filterComponent: true, isSearchable: false, export: true }
        ]
        :
        [
            {
                id: 'isModeled', label: 'Modeled', render: rowData =>
                    <Controls.Checkbox
                        name='isModeled'
                        label=''
                        value={rowData.isModeled === 'True' ? true : false}
                        disabled={true}
                    />,
                filterComponent: true,
                export: (user.isAdmin || user.isModeler) ? true : false,
                hidden: !(user.isAdmin || user.isModeler) ? true: false
            },
            {
                id: 'isComplete', label: 'Complete', render: rowData =>
                    <Controls.Checkbox
                        name='isComplete'
                        label=''
                        value={rowData.isComplete === 'True' ? true : false}
                        disabled={true}
                    />,
                filterComponent: true,
                export: false,
                hidden: !(user.isAdmin || user.isModeler) ? true : false
            },
            {
                id: 'usses', label: 'USSES', render: rowData => 
                    <Controls.Checkbox
                        name='usses'
                        label=''
                        value={rowData.usses === 'True' ? true : false}
                        disabled={true}
                    />,
                filterComponent: true,
                export: (user.isAdmin || user.isModeler) ? true : false,
                hidden: !(user.isAdmin || user.isModeler) ? true : false
                },
            { id: 'scadaPlaceholder', label: 'SCADA Placeholder', filterComponent: true, export: (user.isAdmin || user.isModeler) ? true : false, hidden: !(user.isAdmin || user.isModeler) ? true : false},
            { id: 'id', label: 'Submission ID'},
            { id: 'submissionType', label: 'Submission Type', filterComponent: true},
            { id: 'areaEms', label: 'EMS Area', filterComponent: true, export: false },
            { id: 'dataItemOld', label: 'Old DI' },
            { id: 'dataItem', label: 'DI' },
            { id: 'type', label: 'Type', sorting: false, filterComponent: true, isSearchable: false },
            { id: 'remoteName', label: 'Remote', filterComponent: true, },
            { id: 'qualityStatus', label: 'Q', sorting: false, filterComponent: true, isSearchable: false },
            { id: 'tsStatus', label: 'TS', sorting: false, filterComponent: true, isSearchable: false },
            { id: 'emsStation', label: 'Station', filterComponent: true },
            { id: 'deviceType', label: 'Device Type', filterComponent: true, },
            { id: 'deviceId', label: 'Device', filterComponent: true, },
            { id: 'unitMeas', label: 'Unit Meas', filterComponent: true, },
            { id: 'remoteAddOrRemove', label: 'Permit'},
            { id: 'interconnect', label: 'Interconnect', sorting: false, filterComponent: true, isSearchable: false, export: false},
            { id: 'periodicity', label: 'Periodicity', filterComponent: true, isSearchable: false, export: false }
        ];

    const actions = [
        (source.type === 'current' && !user.isMpOnly) ?
        {
            icon: 'add',
            tooltip: 'Create Submission for selected item.',
            // passes the selected item data to DataPoints.ts createChange method
            onClick: (event, data) => {
                // append to the data the username of the current user 
                // before passing to createChange method
                const finalList = data.map(d => (
                    {
                        ...d,
                        emsStation: d.emsStation.trim(),
                        deviceType: d.deviceType.trim(),
                        deviceId: d.deviceId.trim(),
                        unitMeas: d.unitMeas.trim(),
                        tsStatus: d.tsStatus.trim(),
                        qualityStatus: d.qualityStatus.trim(),
                        createdBy: user.userName
                    }))
                props.createChange(finalList, clearSelection);
            }
            } : null,
        {
            icon: 'refresh',
            tooltip: 'Refresh data',
            isFreeAction: true,
            // passes the selected item data to DataPoints.ts refreshData method
            onClick: () => {
                props.refreshData(source.type, true)
            }
        },
        ((user.isAdmin || user.isModeler) && source.type === 'pending') ?
        {
            icon: () => <CheckIcon />,
            tooltip: 'Mark the selected items as modeled in ICCP',
            location: 'toolbarOnSelect',
            // passes the selected item data to DataPoints.ts updateData method
            onClick: (event, data) => {
                // set isModeled to true for all selected items
                // and append to the data the username of the current user updating them
                // before passing to updateData method
                const finalList = data.map(d => ({
                    ...d,
                    isModeled: true,
                    updatedBy: user.userName
                }))

                props.updateData(finalList, clearSelection)
            }
        } : null,
        ((user.isAdmin || user.isModeler) && source.type === 'pending') ?
        {
            icon: () => <DoneAllIcon />,
            tooltip: 'Mark the selected items as complete in ICCPST',
            location: 'toolbarOnSelect',
            // passes the selected item data to DataPoints.ts updateData method
            onClick: (event, data) => {
                // set isComplete to true for all selected items
                // and append to the data the username of the current user updating them
                // before passing to updateData method
                const finalList = data.map(d => ({
                    ...d,
                    isComplete: true,
                    updatedBy: user.userName
                }))

                props.updateData(finalList, clearSelection)
            }
        } : null,
        {
            icon: () => <HelpOutlineIcon />,
            tooltip: 'Click to see page info and actions',
            isFreeAction: true,
            // sets helpPopup to true, which is the trigger used by the Dialog defined near
            // the bottom of this page to determine if it should be visible or not.
            onClick: () => {
                setHelpPopup(true)
            }
        }
    ];

    // table option info passed to Table.js to define functionality that should be available to the user
    const tableOptions = {
        filtering: true,
        selection: true,
        columnResizable: true,
        hideFilterIcons: true,
        draggable: false,
        exportAllData: true,
        exportFileName: (source.type === 'pending') ? 'iccpst_model_changes' : 'iccpst_data'
    };

    // Holds the list of actions available on the page the user is looking at to provide guidance on how to perform them.
    // Description info varies based on source type (current vs. pending) and if user is an SPP user (admin/modeler) or not.
    const TableDescription = () => {
        return (
            <div>
                {source.type === 'current' ?
                    <>
                        <p>This table displays object IDs from the SPP ICCP Model.</p>
                        <ul style={{ listStyle: 'none' }}>
                            {!user.isMpOnly ?
                                <>
                                    <li>Submissions may be created by selecting entries in the table below and then clicking the <AddIcon /> icon in the top-right corner of this page.</li>
                                </>
                                : null}
                            <li>Data can be exported by clicking the <SaveAltIcon /> icon in the top-right corner of this page.</li>
                            <li>Data can be refreshed by clicking the <RefreshIcon /> icon in the top-right corner of this page.</li>
                        </ul>
                    </>
                    :
                    <>
                        <p>This table displays pending submissions that have been created by you or have changes that impact your remote.</p>
                        <ul style={{ listStyle: 'none' }}>
                            <li>Data can be exported by clicking the <SaveAltIcon /> icon in the top-right corner of this page.</li>
                            {user.isAdmin || user.isModeler ?
                                <>
                                    <li>Data can be marked as modeling complete in ICCP by selecting entries in the table below and then clicking the <CheckIcon /> icon in the top-right corner of this page.</li>
                                    <li>Data can be marked as complete in ICCPST by selecting entries in the table below and then click the <DoneAllIcon /> icon in the top-right corner of this page.</li>
                                </>
                            : null
                            }
                            <li>Data can be refreshed by clicking the <RefreshIcon /> icon in the top-right corner of this page.</li>
                        </ul>
                    </>
                }
                <p>For a more in-depth overview and how-to examples, please refer to the following <a href={process.env.PUBLIC_URL + '/ICCP ONLINE SUBMISSION TOOL User Manual External.pdf'} download={'ICCP ONLINE SUBMISSION TOOL User Manual External.pdf'}>user guide</a>.</p>
            </div>
        );
    }

    // calls Table.js to use the useTable function to create a table with all of the data/settings
    // we've defined on this page.
    const {
        MtlTable,
        clearSelection
    } = useTable(source, headCells, actions, tableOptions, null, false);

    // Result displayed to user. Consists of a grid housing our custom table created from useTable in Table.js
    return (
        <Grid container>
            <Grid item xs={12}>
                <MtlTable />
            </Grid>

            {/*Dialog that pops up when a user clicks the help icon to get info about the page*/}
            <Dialog open={helpPopup}>
                <DialogTitle>
                    Page Details
                </DialogTitle>
                <DialogContent>
                    <TableDescription />
                    <br />
                    <br />
                    <Controls.Button
                        variant='contained'
                        color='primary'
                        text='OK'
                        title='Click to close window.'
                        onClick={() => setHelpPopup(false)}
                    />
                </DialogContent>
            </Dialog>
        </Grid>
        )
}

